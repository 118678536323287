import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MemberSignInModal from './MemberSignInModal';
import { useNavigate } from 'react-router-dom';

const AdminQrScanner = () => {
    const scanner = useRef(null);
    const videoEl = useRef(null);
    const qrBoxEl = useRef(null);
    const [qrOn, setQrOn] = useState(true);
    const [scannedResult, setScannedResult] = useState('');
    const [signedInMember, setSignedInMember] = useState(null);
    const [action, setAction] = useState('signin');
    const debounceTimeout = useRef(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate(); 

    const onScanSuccess = async (result) => {
        if (isProcessing) return;

        console.log(result);
        setScannedResult(result?.data);
        setIsProcessing(true);

        if (scanner.current) {
            scanner.current.stop();
        }

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(async () => {
            try {
                const response = await axios.get(result?.data, { withCredentials: true });
                if (response.status === 200) {
                    const { member, message } = response.data;
                    toast.success(message);
                    setSignedInMember(member);
                    setAction(member.isSignedIn ? 'signin' : 'signout');
                }
            } catch (error) {
                console.error('Error signing in/out member:', error);
                toast.error('Failed to process member.');
            } finally {
                setIsProcessing(false); 
            }
        }, 500);
    };

    // Handle scan failure
    const onScanFail = (err) => {
        console.log(err);
    };

    // Handle modal close and restart the scanner
    const handleCloseModal = () => {
        setSignedInMember(null);
        if (scanner.current) {
            scanner.current.start(); 
        }
    };

    // Handle stopping the scanner and exiting the scanning process
    const handleExitScanner = () => {
        if (scanner.current) {
            scanner.current.stop(); 
        }
        setQrOn(false); 
        navigate('/admin-studio-dashboard'); 
    };

    useEffect(() => {
        if (videoEl.current && !scanner.current) {
            scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
                onDecodeError: onScanFail,
                preferredCamera: 'environment',
                highlightScanRegion: true,
                highlightCodeOutline: true,
                overlay: qrBoxEl.current || undefined,
            });

            scanner.current.start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) setQrOn(false);
                });
        }

        return () => {
            if (scanner.current) {
                scanner.current.stop();
            }
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!qrOn) {
            alert("Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.");
        }
    }, [qrOn]);

    return (
        <div className="qr-reader">
            <button onClick={handleExitScanner} className="close-scanner-button">
                Exit QR Scanner
            </button>
            <video ref={videoEl} style={{ display: qrOn ? 'block' : 'none' }}></video>
            <div ref={qrBoxEl} className="qr-box">
                <img
                    src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1719243841/qr-frame_npsl74.svg"
                    alt="Qr Frame"
                    width={256}
                    height={256}
                    className="qr-frame"
                    style={{ display: qrOn ? 'block' : 'none' }}
                />
            </div>
            
            {signedInMember && (
                <MemberSignInModal 
                    member={signedInMember} 
                    onClose={handleCloseModal}
                    action={action}
                />
            )}
        </div>
    );
};

export default AdminQrScanner;
