import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  LoginPage,
  SignupPage,
  ActivationPage,
} from "./routes/Routes.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./redux/store";
import { loadSeller, loadUser } from "./redux/actions/user";
import { getAllProducts } from "./redux/actions/product";
import { getAllEvents } from "./redux/actions/event";
import axios from "axios";
import { server } from "./server";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CookieConsent from "./components/CookieConsent";
import { useCookies } from 'react-cookie';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ForgotPasswordPage from "./pages/ForgotPasswordPage.jsx";
import ForgotPasswordResetPassword from "./components/Login/ForgotPasswordReset.jsx";
import MembershipProcess from "./components/Membership/MembershipProcess.jsx";
import AdminQrScanner from "./components/Membership/AdminQrScanner.jsx";
import MembershipSuccess from "./components/Membership/MembershipSuccess.jsx";
import StudioAdminDashboard from "./components/Membership/StudioAdminDashboard.jsx";
import ProtectedAdminRoute from "./routes/ProtectedAdminRoute";





const App = () => {

  const [stripeApikey, setStripeApiKey] = useState("");
  const [cookies] = useCookies(["cookieConsent"]);

  async function getStripeApikey() {
    const { data } = await axios.get(`${server}/payment/stripeapikey`);
    setStripeApiKey(data.stripeApikey);
  }
  useEffect(() => {
    Store.dispatch(loadUser());
    Store.dispatch(loadSeller());
    Store.dispatch(getAllProducts());
    Store.dispatch(getAllEvents());
    getStripeApikey();
  }, []);


  

  return (
    <BrowserRouter>
      {stripeApikey && (
        <Elements stripe={loadStripe(stripeApikey)}>
          <Routes>
            <Route
              path="/"
              element={
                
                  <MembershipProcess />
                
              }
            />
          </Routes>
        </Elements>
      )}
      {!cookies.cookieConsent && <CookieConsent />}
      <Routes>
      <Route path="/membership-success" element={<MembershipSuccess />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        
        
        <Route
          path="/activation/:activation_token"
          element={<ActivationPage />}
        />
        <Route
          path="/*"
          element={
            
              <Routes>
       
        <Route path="/login" element={<LoginPage />} />
       
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password/:token" element={<ForgotPasswordResetPassword />} />
        <Route path="/signup" element={<SignupPage />} />

        
        {/* Admin Routes */}
        <Route
          path="/admin-studio-dashboard"
          element={
            <ProtectedAdminRoute>
              <StudioAdminDashboard />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-studio-qr-scanner"
          element={
            <ProtectedAdminRoute>
              <AdminQrScanner />
            </ProtectedAdminRoute>
          }
        />
        
        </Routes>
            
          }
        />
      </Routes>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

export default App;
