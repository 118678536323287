import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from '../../server';

const MemberDetails = ({ member, onClose }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalAction, setModalAction] = useState('');

    const handleCancelMembership = async () => {
        try {
            const response = await axios.post(`${server}/membership/cancel-membership`, {
                memberId: member._id,
            });
            toast.success(response.data.message);
            onClose();
        } catch (error) {
            console.error('Error canceling membership:', error);
            toast.error('Failed to cancel membership.');
        }
    };

    const handleReinstateMembership = async () => {
        try {
            const response = await axios.post(`${server}/membership/reinstate-membership`, {
                memberId: member._id,
            });
            toast.success(response.data.message);
            onClose();
        } catch (error) {
            console.error('Error reinstating membership:', error);
            toast.error('Failed to reinstate membership.');
        }
    };

    const handleConfirmAction = () => {
        setShowModal(false);
        if (modalAction === 'cancel') {
            handleCancelMembership();
        } else if (modalAction === 'reinstate') {
            handleReinstateMembership();
        }
    };

    const handleCancelClick = () => {
        setModalAction('cancel');
        setShowModal(true);
    };

    const handleReinstateClick = () => {
        setModalAction('reinstate');
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="member-details-overlay">
            <div className="member-details-modal">
                <button className="member-close-button" onClick={onClose}>×</button>
                <h2>{`${member.firstName} ${member.lastName}`}</h2>
                <img src={member.photo} alt={`${member.firstName} ${member.lastName}`} className="member-photo-large" />
                <ul>
                    <li><strong>Email:</strong> <span>{member.email}</span></li>
                    <li><strong>Date of Birth:</strong> <span>{new Date(member.dob).toLocaleDateString()}</span></li>
                    <li><strong>Tier:</strong> <span>{member.tier}</span></li>
                    <li><strong>Pricing Plan:</strong> <span>{member.pricingPlan}</span></li>
                    <li><strong>Subscription ID:</strong> <span>{member.stripeSubscriptionId}</span></li>
                    <li><strong>Status:</strong> <span>{member.status}</span></li>
                    {member.currentPeriodStart && (
                        <li><strong>Current Period Start:</strong> <span>{new Date(member.currentPeriodStart).toLocaleDateString()}</span></li>
                    )}
                    {member.currentPeriodEnd && (
                        <li><strong>Current Period End:</strong> <span>{new Date(member.currentPeriodEnd).toLocaleDateString()}</span></li>
                    )}
                    <li><strong>Price ID:</strong> <span>{member.priceId}</span></li>
                    <li><strong>Signed In:</strong> <span>{member.isSignedIn ? 'Yes' : 'No'}</span></li>
                    <li><strong>Created At:</strong> <span>{new Date(member.createdAt).toLocaleDateString()}</span></li>
                    <li><strong>Updated At:</strong> <span>{new Date(member.updatedAt).toLocaleDateString()}</span></li>
                </ul>
                {member.qrCodeUrl && (
                    <div className="qr-code-container">
                        <h3>QR Code</h3>
                        <img src={member.qrCodeUrl} alt="QR Code" className="qr-code-image" />
                        <a href={member.qrCodeUrl} download={`QR_${member.firstName}_${member.lastName}.png`} className="qr-download-link">
                            Download QR Code
                        </a>
                    </div>
                )}
                {member.status === 'canceled' ? (
                    <button className="member-reinstate-button" onClick={handleReinstateClick}>Reinstate Membership</button>
                ) : (
                    <button className="member-cancel-button" onClick={handleCancelClick}>Cancel Membership</button>
                )}

                {showModal && (
                    <div className="cancel-modal-overlay">
                        <div className="cancel-modal">
                            <p>Are you sure you want to {modalAction === 'cancel' ? 'cancel' : 'reinstate'} this membership?</p>
                            <button className="cancel-confirm-button" onClick={handleConfirmAction}>Yes</button>
                            <button className="cancel-close-button" onClick={handleCloseModal}>No</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MemberDetails;




