import React, { useEffect } from 'react'
import Login from "../components/Login/Login.jsx";

const LoginPage = () => {
  
  return (
    <div>
        <Login />
    </div>
  )
}

export default LoginPage;